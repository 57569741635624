import { PAGE } from '~/constants/router'
import { PAGE_PARAMS } from '~/constants/common'
import { LOBBY_GAME_URLS } from '~/config/page-url'
const PATH_IMAGE = '/assets/images/components/desktop/footer/'
const BRANCH_NAME = process.env.BRANCH_NAME

export const FOOTER_NAVIGATION = [
  {
    title: 'Thể thao',
    subCategories: [
      { title: 'Lịch thi đấu', link: PAGE.SPORTS_SCHEDULE },
      { title: 'Cược thể thao', link: PAGE.SPORTS }
    ]
  },
  {
    title: 'Trò chơi',
    subCategories: [
      { title: 'Bắn cá', link: LOBBY_GAME_URLS.BAN_CA },
      { title: 'Slots', link: LOBBY_GAME_URLS.SLOTS_GAME },
      { title: 'Nổ Hũ', link: LOBBY_GAME_URLS.NO_HU },
      { title: 'Xổ Số', link: LOBBY_GAME_URLS.LO_DE },
      { title: 'Table game', link: LOBBY_GAME_URLS.TABLE_GAME },
      { title: 'Quay số', link: LOBBY_GAME_URLS.QUAY_SO },
      { title: 'Games nhanh', link: LOBBY_GAME_URLS.QUICK_GAMES },
      { title: 'Games Bài', link: LOBBY_GAME_URLS.GAME_BAI }
    ]
  },
  {
    title: 'Về chúng tôi',
    subCategories: [
      { title: 'Giới thiệu', link: `${PAGE.ABOUT_US}` },
      { title: 'Điều khoản - Điều kiện', link: `${PAGE.ABOUT_US}?page=${PAGE_PARAMS.POLICY}` },
      { title: 'Bảo mật', link: `${PAGE.ABOUT_US}?page=${PAGE_PARAMS.SECURITY}` },
      { title: 'Quy định chung', link: `${PAGE.ABOUT_US}?page=${PAGE_PARAMS.RULE}` }
    ]
  },
  {
    title: 'Thông tin',
    subCategories: [
      { title: 'Khuyến mãi / Sự kiện', link: PAGE.PROMOTION },
      { title: 'Tin tức', link: PAGE.NEWS }
    ]
  },
  {
    title: 'Hỗ trợ',
    subCategories: [
      {
        title: 'Telegram CSKH',
        type: 'BUTTON',
        icon: '/assets/images/components/desktop/footer/icon-telegram.svg',
        link: process.env.TELEGRAM_CSKH
      },
      {
        title: `Cộng đồng ${BRANCH_NAME}`,
        type: 'BUTTON',
        icon: '/assets/images/components/desktop/footer/icon-group.svg',
        link: process.env.TELEGRAM_247
      }
    ]
  }
]

export const DEPOSIT_LIST = [
  {
    title: 'CodePay',
    image: `${PATH_IMAGE}codepay.svg`,
    link: PAGE.ACCOUNT_DEPOSIT_DA
  },
  {
    title: 'Codeplay2',
    image: `${PATH_IMAGE}codepay2.svg`,
    link: PAGE.ACCOUNT_DEPOSIT_MOMO
  },
  {
    title: 'Ví điện tử',
    image: `${PATH_IMAGE}digital-wallet.svg`,
    link: PAGE.ACCOUNT_DEPOSIT_MOMO
  },
  {
    title: 'Tiền ảo',
    image: `${PATH_IMAGE}crypto.svg`,
    link: PAGE.ACCOUNT_DEPOSIT_CRYPTO
  },
  {
    title: 'Thẻ cào',
    image: `${PATH_IMAGE}card.svg`,
    link: PAGE.ACCOUNT_DEPOSIT_CARD
  }
]

export const FOOTER_CONTENT = {
  SLOGAN: `${BRANCH_NAME} - Nhà cái cá cược uy tín nhất Châu Á, nạp rút siêu tốc.`,
  DESCRIPTION: `Nhà cái ${BRANCH_NAME} là trang cược bóng đá nhanh nhất trên thị trường hiện nay, hoàn trả mỗi ngày cao nhất 1.8%. ${BRANCH_NAME} cung cấp hai loại bảng kèo K-Sports; C-Sports và tỷ lệ cược liên tục cập nhật từng giây. Lịch thi đấu mỗi ngày, phân tích trận đấu, soi kèo banh Ngoại Hạnh Anh, Cúp C1, Serie A, Laliga, Bundesliga, Ligue 1,... và tất cả các giải lớn nhỏ khác. Trải nghiệm mượt mà, mọi lúc mọi nơi.`,
  SLOGAN_DESCRIPTION: `${BRANCH_NAME} là nhà cái cá cược trực tuyến hàng đầu tại Châu Á, với nền tảng công nghệ tiên tiến chúng tôi đã và đang mang đến những sản phẩm cá cược chất lượng nhất như: Thể Thao, Sòng Bài, Lô Đề, Number Game, Keno...`,
  VIEW_MORE: 'xem thêm',
  COPY_RIGHT: `Copyright © 2024 Powered By ${BRANCH_NAME} All Rights Reserved.`
}
